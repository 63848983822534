@font-face {
  font-family: 'bouldextralight';
  src: url('bould-extralight-webfont.eot');
  src: url('bould-extralight-webfont.eot?#iefix') format('embedded-opentype'),
    url('bould-extralight-webfont.woff2') format('woff2'),
    url('bould-extralight-webfont.woff') format('woff'),
    url('bould-extralight-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bouldlight';
  src: url('bould-light-webfont.eot');
  src: url('bould-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('bould-light-webfont.woff2') format('woff2'),
    url('bould-light-webfont.woff') format('woff'),
    url('bould-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bouldmedium';
  src: url('bould-medium-webfont.eot');
  src: url('bould-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('bould-medium-webfont.woff2') format('woff2'),
    url('bould-medium-webfont.woff') format('woff'),
    url('bould-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bouldregular';
  src: url('bould-regular-webfont.eot');
  src: url('bould-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('bould-regular-webfont.woff2') format('woff2'),
    url('bould-regular-webfont.woff') format('woff'),
    url('bould-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bouldsemibold';
  src: url('bould-semibold-webfont.eot');
  src: url('bould-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('bould-semibold-webfont.woff2') format('woff2'),
    url('bould-semibold-webfont.woff') format('woff'),
    url('bould-semibold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bouldthin';
  src: url('bould-thin-webfont.eot');
  src: url('bould-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('bould-thin-webfont.woff2') format('woff2'),
    url('bould-thin-webfont.woff') format('woff'),
    url('bould-thin-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'silkamonoregular';
  src: url('silkamono-regular-webfont.eot'),
    url('silkamono-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('silkamono-regular-webfont.woff2') format('woff2'),
    url('silkamono-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
